import request from "@/util/axios"
import Qs from 'qs'
const http ={
/**
* methods: 请求方法
* @param url 请求地址
* @param params 请求参数
*/

	get(url,params){
		const config = {
			method: 'get',
			url:url,
			headers: {'Content-Type': 'application/json;charset=utf-8'},
			timeout: 10000,
			responseType: 'json'
		}
		if(params){
			config.params = params
		}
		return request(config)
	},
	post(url,params){
		const config = {
			method: 'post',
			url:url,
			headers: {'Content-Type': 'application/json;charset=utf-8'},
			timeout: 10000,
			responseType: 'json'
			}
		if(params){
			config.data = params
		}
		return request(config)
	},
	uppic(url,params){
		const config = {
			method: 'post',
			url:url,
			headers: {'Content-Type': 'multipart/form-data'}
			}
		if(params){
			config.data = params
		}
		return request(config)
	}
}

//导出
export default http