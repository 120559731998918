<template>
  <div>
	  <el-row :gutter="20">
		<el-col :span="12">
			<el-card class="box-card">
			  <div slot="header" class="clearfix">
			    <span>畜牧新闻</span>
			    <el-button style="float: right; padding: 3px 0" type="text">刷新数据</el-button>
			  </div>
			  <el-row gutter="4">
			    <el-col :span="8">
			      <div>
			        <el-statistic :value="data.news.all" title="总数"></el-statistic>
			      </div>
			    </el-col>
			    <el-col :span="8">
			      <div>
			        <el-statistic :value="data.news.all-data.news.no" title="已审核"></el-statistic>
			      </div>
			    </el-col>
			    <el-col :span="8">
			      <div>
			        <el-statistic :value="data.news.no" title="未审核"></el-statistic>
			      </div>
			    </el-col>
			  </el-row>
			</el-card>
		</el-col>
		<el-col :span="12">
			<el-card class="box-card">
			  <div slot="header" class="clearfix">
			    <span>服务中心</span>
			    <el-button style="float: right; padding: 3px 0" type="text">刷新数据</el-button>
			  </div>
			  <el-row gutter="4">
			    <el-col :span="8">
			      <div>
			        <el-statistic :value="data.fuwu.all" title="总数"></el-statistic>
			      </div>
			    </el-col>
			    <el-col :span="8">
			      <div>
			        <el-statistic :value="data.fuwu.all-data.fuwu.no" title="已审核"></el-statistic>
			      </div>
			    </el-col>
			    <el-col :span="8">
			      <div>
			        <el-statistic :value="data.fuwu.no" title="未审核"></el-statistic>
			      </div>
			    </el-col>
			  </el-row>
			</el-card>
		</el-col>
		<el-col :span="12">
			<el-card class="box-card">
			  <div slot="header" class="clearfix">
			    <span>用户管理</span>
			    <el-button style="float: right; padding: 3px 0" type="text">刷新数据</el-button>
			  </div>
			  <el-row gutter="4">
			    <el-col :span="6">
			      <div>
			        <el-statistic :value="data.user.com" title="企业用户"></el-statistic>
			      </div>
			    </el-col>
			    <el-col :span="6">
			      <div>
			        <el-statistic :value="data.user.user" title="内部用户"></el-statistic>
			      </div>
			    </el-col>
			    <el-col :span="6">
			      <div>
			        <el-statistic :value="data.user.com+data.user.user-data.user.no" title="已审核"></el-statistic>
			      </div>
			    </el-col>
				<el-col :span="6">
				  <div>
				    <el-statistic :value="data.user.no" title="未审核"></el-statistic>
				  </div>
				</el-col>
			  </el-row>
			</el-card>
		</el-col>
		<el-col :span="12">
			<el-card class="box-card">
			  <div slot="header" class="clearfix">
			    <span>假条管理</span>
			    <el-button style="float: right; padding: 3px 0" type="text">刷新数据</el-button>
			  </div>
			  <el-row gutter="4">
				<el-col :span="6">
				  <div>
				    <el-statistic :value="data.jiatiao.all" title="全部"></el-statistic>
				  </div>
				</el-col>
			    <el-col :span="6">
			      <div>
			        <el-statistic :value="data.jiatiao.yes" title="已上传"></el-statistic>
			      </div>
			    </el-col>
			    <el-col :span="6">
			      <div>
			        <el-statistic :value="data.jiatiao.loading" title="待审核"></el-statistic>
			      </div>
			    </el-col>
			    <el-col :span="6">
			      <div>
			        <el-statistic :value="data.jiatiao.no" title="未通过"></el-statistic>
			      </div>
			    </el-col>
			  </el-row>
			</el-card>
		</el-col>
	</el-row>
  </div>
</template>

<script>
  export default {
    data() {
      return {
		data:{
			news:{
				all:8888,
				no:2222
			},
			fuwu:{
				all:6666,
				no:1111
			},
			user:{
				com:666,
				user:888,
				no:111
			},
			jiatiao:{
				all:9999,
				yes:6666,
				loading:3311,
				no:22
			}
		}
      };
    },
  };
</script>
<style>

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }

  .box-card {
	margin-top: 18px;
  }
</style>