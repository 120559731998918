<template>
    <el-container>
		<el-header style="padding: 0px 0px;">
			<el-row type="flex" justify="space-between">
				<div class="logo" style="width: 200px;">畜牧云服务管理系统</div>
				<div>
					<el-menu background-color="#545c64" text-color="#fff" @select="handleSelect">
					<el-submenu style="width:130px;" index="2">
						<template slot="title">用户操作</template>
							<el-menu-item index="pass">修改密码</el-menu-item>
							<el-menu-item index="out">退出登录</el-menu-item>
					</el-submenu>
					</el-menu>
				</div>
			</el-row>
		</el-header>
		<el-container>
		    <el-aside style="padding-top: 60px;width: 200px;">
				<el-menu :default-active="$router.currentRoute.fullPath" router background-color="#545c64" text-color="#fff" active-text-color="#ffd04b">
					    <el-menu-item v-for="(item,index) in menuList" :key="index+''" :index="item.path">
					        <i :class="item.icon"></i>
					        <span slot="title">{{item.name}}</span>
					    </el-menu-item>
					</el-menu>
			</el-aside>
			<el-main style="padding-top: 60px;"><router-view/></el-main>
		</el-container>
    </el-container>
</template>
<script>
 
export default {
    name: 'IndexView',
    data() {
        return {
            menuList: []
        }
    },
    created(){
		this.menuList=this.$router.options.routes[1].children
    },
    methods: {
		handleSelect(index){
			if(index == 'out'){
				//清空所有的存储数据
				window.localStorage.clear();
				//跳转
				this.$router.push({ path: "/login" });
			}
		}
    }
 
}
</script>
<style>
.el-container{
	position: absolute; 
	width: 100%; 
	top: 0; 
	left: 0;
	bottom: 0;
}
.logo{
	color: #fff;
	width: 200px;
	height: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.el-header{
	z-index: 1000;
	background: #545c64;
}
.el-aside{
	background: #545c64;
}

</style>