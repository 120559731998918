<template>
	<div class="news">
		<template>
			<el-button @click="add(0)" type="primary" size="small" icon="el-icon-plus" style="margin-right: 20px;">添加</el-button>
			<span style="margin-right: 20px;" slot="suffix">按分类查看:</span>
			<el-radio-group v-model="cid" @change="getValue()">
				<el-radio :label="0">全部</el-radio>
				<!-- 如果选项是通过v-for获得的，那么就要绑定key，不然会有警告 -->
				<el-radio v-for="(item,i) in cname" :key="i" :label="item.cid">{{item.cname}}</el-radio>
			</el-radio-group>
		</template>
    <el-table :data="tableData" style="width: 100%">
		<el-table-column label="ID" width="60">
			<template slot-scope="scope">
				<span>{{ scope.row.id }}</span>
			</template>
		</el-table-column>
	<el-table-column label="动态类型" width="100">
		<template slot-scope="scope">
			<span>{{ scope.row.cname }}</span>
		</template>
	</el-table-column>
	<el-table-column label="内容标题" width="300">
		<template slot-scope="scope">
			<span class="info">{{ scope.row.title }}</span>
		</template>
	</el-table-column>
	<el-table-column label="内容正文">
		<template slot-scope="scope">
			<span class="info">{{ scope.row.info }}</span>
		</template>
	</el-table-column>
    <el-table-column fixed="right" width="240" label="操作">
        <template slot-scope="scope">
			<el-button v-if="scope.row.shenhe==1"
			size="mini"
			type="primary"
			@click="handleshenhe(scope.$index,scope.row,0)">已显示</el-button>
			<el-button v-if="scope.row.shenhe==0"
			size="mini"
			@click="handleshenhe(scope.$index,scope.row,1)">已隐藏</el-button>
			<el-button
            size="mini"
			type="primary"
            @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
			<el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
	<el-pagination
	      @current-change="handleCurrentChange"
	      :page-size="10"
	      layout="total, prev, pager, next"
	      :total="total"
		  :current-page="newspageNum"
		  hide-on-single-page
		  background>
	    </el-pagination>
		<el-dialog show="show" :visible.sync="open" :center="true" :close-on-click-modal="false" :close-on-press-escape="false" title="新增">
		       <el-form ref="form" :model="form" label-width="80px">
				   <el-form-item label="选择分类">
				     <el-radio-group v-model="form.cid">
				     	<!-- 如果选项是通过v-for获得的，那么就要绑定key，不然会有警告 -->
				     	<el-radio v-for="(item,i) in cname" :key="i" :label="item.cid">{{item.cname}}</el-radio>
				     </el-radio-group>
				   </el-form-item>
				   <el-form-item label="内容标题" prop="title">
				   		<el-input maxlength="120" v-model="form.title" placeholder="请输入标题"></el-input>
				   </el-form-item>
				   <el-form-item label="内容正文" prop="content">
					   <div style="border: 1px solid #ccc;">
					       <Toolbar
					           style="border-bottom: 1px solid #ccc"
					           :editor="editor"
					           :defaultConfig="toolbarConfig"
					           :mode="mode"
					       />
					       <Editor
					           style="height: 500px; overflow-y: hidden;"
					           v-model="form.content"
					           :defaultConfig="editorConfig"
					           :mode="mode"
					           @onCreated="onCreated"
							   @customPaste="PasteHtml"
					       />
					   </div>
				   </el-form-item>
		       </el-form>
			   <el-button @click="upnew(form.id)" type="primary" size="small">保存</el-button>
			   <el-button @click="open=false" type="primary" size="small">取消</el-button>
		</el-dialog>
	</div>
</template>
<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
export default {
	components: { Editor, Toolbar },
	data() {
		return {
			tableData: [],
			newspageNum:1,
			total:0,
			radio:0,
			cid:0,
			cname:[],
			open:false,
			form: {
				id:0,
				cid:1,
				title:'',
				content:'',
				images_del:[],
				images:[]
			},
			readOnlys:false,
			images:[],
			editor: null,
			toolbarConfig: {
				excludeKeys:[
					'insertVideo',
					'insertLink',
					'insertImage'
				]
			},
			editorConfig: { 
			    placeholder: '请输入内容...' ,
			    MENU_CONF: {
					uploadImage: {
						customUpload: this.uploadImg,
					}
				}
			},
			mode: 'simple', // or 'simple'
		}
    },
	beforeMount(){
		this.$http.get("/api/adminnewsclass").then((res)=>{
			this.cname = res.c
		})
		this.getdata(1)
	},
    methods: {
		onCreated(editor) {
			this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
			this.editor.setHtml(this.form.content)
		},
		async uploadImg(file, insertFn){
			let uploadForm = new FormData()
			uploadForm.append("image", file)
			await this.$http.uppic('/api/adminnewup',uploadForm).then((res)=>{
				this.images.push(res.url)
				insertFn('https://xm.wuzhibbs.com/images_up/'+res.url)
			})
		},
		PasteHtml(editor, event){
			let html = event.clipboardData.getData('text/html').replace(/<\/?(img)[^>]*>/gi, '')
			html = html.replace(/style=\"(.*?)\"/gi, '')
			editor.dangerouslyInsertHtml(html)
			event.preventDefault()
			return false
		},
		upnew(id){
			this.form.images_del=[]
			this.form.images=[]
			let images1 = this.images
			let images2 = this.editor.getElemsByType('image')
			let len1 = images1.length
			let len2 = images2.length
			for (let i = 0; i < len1; i++) {
				let panduan = 0
				for (let z = 0; z < len2; z++) {
					let image = images2[z].src.replace(new RegExp("https://xm.wuzhibbs.com/images_up/"),"")
					if(images1[i].indexOf(image) != -1){
						panduan = 1
					}
				}
				if (panduan != 1){
					this.form.images_del.push(images1[i])
				}else{
					this.form.images.push(images1[i])
				}
			}
			if(id > 0){
				this.$http.post("/api/adminnewedi",this.form).then((res)=>{
					this.$message({
						message: res.msg,
						type: 'success',
					});
					this.open = false
					this.getdata()
				})
			}else{
				this.$http.post("/api/adminnewadd",this.form).then((res)=>{
					this.$message({
						message: res.msg,
						type: 'success',
					});
					this.open = false
					this.getdata()
				})
			}
		},
		getdata(e){
			if(e >= 1){
				this.newspageNum = e
			}
		    let url = "/api/adminnews"
		    let params={cid:this.cid,pagenum:this.newspageNum}
		    let res;
		    res = this.$http.post(url, params).then((res)=>{
				console.log(res)
				this.tableData = res.data
				this.total = res.total
				
            },(err)=>{
                console.log(err);
            })
		},
		handleDelete(index,data){
			console.log(index,data)
			this.$confirm('此操作将彻底删除该内容, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				}).then(() => {
					let url = "/api/adminnewsdel";
					let params={id:data.id}
					let res;
					res = this.$http.post(url, params).then((res)=>{
						let all = (this.total - 1)
						let newpages = Math.ceil(all/10)
						console.log(all,newpages)
						if(this.newspageNum > newpages){
							this.handleCurrentChange(this.newspageNum-1)
						}else{
							this.handleCurrentChange(this.newspageNum)
						}
						this.$message({
							type: 'success',
						    message: res.msg
						  });
					},(err)=>{
					    console.log(err);
					})
			        }).catch(() => {    
						console.log('取消删除');
			        });
		},
		handleshenhe(index,data,type){
			if(type == 1){
				var shenhe = 1;
			}else{
				var shenhe = 0;
			}
			let url = "/api/adminnewssh";
			let params={id:data.id,sh:shenhe}
			let res;
			res = this.$http.post(url, params).then((res)=>{
				this.tableData[index].shenhe = shenhe
				this.$message({
					type: 'success',
				    message: res.msg
				  });
			},(err)=>{
			    console.log(err);
			})
		},
		getValue(){
			this.getdata(1)
		},
		handleCurrentChange(e){//分页选择
			this.newspageNum = e
			this.getdata()
		},
		add(){
			this.open = true
			this.$nextTick(() => {
				this.images=[]
				this.form.id=0
				this.form.cid=1
				this.form.title=""
				this.form.content=""
			})
		},
		handleEdit(index,data){
			this.open=true
			this.$nextTick(() => {
				this.images=data.images
				this.form.id=data.id
				this.form.cid=data.cid
				this.form.title=data.title
				this.form.content=data.content
			})
		},
    },
	beforeDestroy() {
	    const editor = this.editor
	    if (editor == null) return
	    editor.destroy() // 组件销毁时，及时销毁编辑器
	}
}
</script>
<style src="@wangeditor/editor/dist/css/style.css">
	.news{
		padding-top: 15px;
	}
	.info {
	    display: -webkit-box;
	    text-overflow: ellipsis;
	    overflow: hidden;
	    -webkit-line-clamp: 3;
	    -webkit-box-orient: vertical;
	}
</style>