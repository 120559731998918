<template>
	<div class="news">
		<template>
			<el-button @click="add()" type="primary" size="small" icon="el-icon-plus" style="margin-right: 20px;">添加</el-button>
			<el-button @click="addall()" type="primary" size="small" icon="el-icon-plus" style="margin-right: 20px;">批量导入</el-button>
			<span style="margin-right: 20px;" slot="suffix">按分类查看:</span>
			<el-radio-group v-model="cid" @change="getValue()">
				<!-- 如果选项是通过v-for获得的，那么就要绑定key，不然会有警告 -->
				<el-radio v-for="(item,i) in c_class" :key="i" :label="item.cid">{{item.cname}}</el-radio>
			</el-radio-group>
		</template>
    <el-table :data="tableData" style="width: 100%">
		<el-table-column label="ID" width="60">
			<template slot-scope="scope">
				<span>{{ scope.row.id }}</span>
			</template>
		</el-table-column>
	<el-table-column v-if="cid==0" label="UID" width="60">
		<template slot-scope="scope">
			<span class="info">{{ scope.row.uid }}</span>
		</template>
	</el-table-column>
	<el-table-column label="姓名" width="120">
		<template slot-scope="scope">
			<el-input v-if="isshow[scope.$index]" type="text" clearable v-model.trim="scope.row.name"/>
			<!-- 展示状态下显示 -->
			<span class="info" v-if="!isshow[scope.$index]">{{scope.row.name}}</span>
		</template>
	</el-table-column>
	<el-table-column v-if="cid==0" label="身份证号码" width="175">
		<template slot-scope="scope">
			<span class="info">{{ scope.row.pin }}</span>
		</template>
	</el-table-column>
	<el-table-column :label="cid==0?'科室':'公司'" width="220">
		<template slot-scope="scope">
			<el-select v-if="cid==0" @change="dokeshi($event,scope.$index)" :value="scope.row.bname" placeholder="请选择部门">
			    <el-option
			      v-for="(item,i) in b_class"
			      :key="i"
			      :label="item.bname"
				  :value="[item.bid,item.bname]">
			    </el-option>
			</el-select>
			<el-input v-if="cid!=0 && isshow[scope.$index]" type="text" clearable v-model.trim='scope.row.com'/>
			<span v-if="cid!=0 && !isshow[scope.$index]">{{ scope.row.com }}</span>
		</template>
	</el-table-column>
	<el-table-column label="手机号码" width="140">
		<template slot-scope="scope">
			<span>{{ scope.row.phone }}</span>
		</template>
	</el-table-column>
	<el-table-column label="头像">
		<template slot-scope="scope">
			<el-upload
			  action="action"
			  :data="{id:scope.row.id}"
			  :show-file-list="false"
			  :before-upload="beforeAvatarUpload"
			  :http-request="uploadFile">
			  <img v-if="scope.row.touxiang" style="width: 60px;height: 60px;" :src="'https://xm.wuzhibbs.com/images_up/'+scope.row.touxiang" class="avatar">
			  <i v-else class="el-icon-plus add"></i>
			</el-upload>
		</template>
	</el-table-column>
	<el-table-column width="80" label="编辑">
	    <template slot-scope="scope">
			<el-button
			v-if="cid!=0 && isshow[scope.$index]"
			size="mini"
			type="success"
			icon="el-icon-check"
			@click="handelCheck(scope.row,scope.$index)"
			/>
			<el-button
			v-if="cid!=0 && !isshow[scope.$index]"
			size="mini"
			type="primary"
			icon="el-icon-edit"
			@click="handleEdit(scope.$index)"
			/>
	    </template>
	  </el-table-column>
    <el-table-column width="200" label="操作">
        <template slot-scope="scope">
			<el-button
			v-if="scope.row.shenhe==1"
			size="mini"
			type="primary"
			@click="handleshenhe(scope.$index,scope.row,0)">已审核</el-button>
			<el-button
			v-if="scope.row.shenhe==0"
			size="mini"
			@click="handleshenhe(scope.$index,scope.row,1)">未审核</el-button>
			<el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
	<el-pagination
	      @current-change="handleCurrentChange"
	      :page-size="10"
	      layout="total, prev, pager, next"
	      :total="total"
		  :current-page="pageNum"
		  hide-on-single-page
		  background>
	    </el-pagination>
		<el-dialog :visible.sync="open" :center="true" :close-on-click-modal="false" :close-on-press-escape="false" title="新增">
		       <el-form ref="form" :model="form" :rules="rules" label-width="80px">
				   <el-form-item label="选择分类" prop="cid">
				     <el-radio-group v-model="form.cid" @input="radioinput">
						<!-- 如果选项是通过v-for获得的，那么就要绑定key，不然会有警告 -->
						<el-radio v-for="(item,i) in c_class" :key="i" :label="item.cid">{{item.cname}}</el-radio>
				     </el-radio-group>
				   </el-form-item>
				   <el-form-item v-if="form.cid == 0" label="身份证号" prop="pin">
					   <el-input @input="pininput" :maxlength="18" placeholder="请输入身份证号码" v-model="form.pin"></el-input>
				   </el-form-item>
				   <el-form-item v-if="form.cid == 0" label="用户姓名" prop="name">
				   		<el-input placeholder="输入身份证后自动获取" v-model="form.name" disabled></el-input>
				   </el-form-item>
				   <el-form-item v-if="form.cid == 0" label="考勤ID" prop="uid">
				   		<el-input placeholder="输入身份证后自动获取" v-model="form.uid" disabled></el-input>
				   </el-form-item>
				   <el-form-item label="手机号码" prop="phone">
					<el-input minlength="11" maxlength="11" v-model="form.phone" placeholder="请输入手机号码"></el-input>
				   </el-form-item>
				   <el-form-item label="用户密码" prop="pass">
					   <el-input show-password placeholder="请输入密码" v-model="form.pass"></el-input>
				   </el-form-item>
				   <el-form-item v-if="form.cid != 0" label="用户姓名" prop="name">
				   		<el-input placeholder="请输入用户姓名" v-model="form.name"></el-input>
				   </el-form-item>
				   <el-form-item v-if="form.cid != 0" label="企业名称" prop="com">
					   <el-input placeholder="请输入企业名称" v-model="form.com"></el-input>
				   </el-form-item>
				   <el-form-item v-if="form.cid == 0" label="选择部门" prop="bid">
					   <el-select v-model="form.bid" filterable placeholder="请选择部门">
					       <el-option
					         v-for="(item,i) in b_class"
					         :key="i"
					         :label="item.bname"
					         :value="item.bid">
					       </el-option>
					     </el-select>
				   </el-form-item>
		       </el-form>
			   <el-button @click="upuser" type="primary" size="small">保存</el-button>
		</el-dialog>

	</div>
</template>

<script>
export default {
	data() {
		return {
			cid:0,
			c_class:[],
			b_class:[],
			bname:'',
			tableData: [],
			pageNum:1,
			total:0,
			open:false,
			// 控制参数表格输入显示
			isshow: [],
			form: {
				cid:0,
				bid:0,
				phone:'',
				pass:'',
				name:'',
				com:'',
				pin:'',
				uid:''
			},
			class:[],
			rules: {
				cid:[
					{ required: true, message: '请选择分类',trigger:'blur'}
				],
				bid:[
					{ required: true, message: '请选择部门',trigger:'blur'}
				],
				phone: [
					{ required: true, message: '手机号码不能为空',trigger:'blur'},
					{ pattern:/^1[34578]\d{9}$/, message: '手机号码格式错误',trigger:'blur'}
			    ],
				pass:[
					{ required: true, message: '用户密码不能为空',trigger:'blur'},
					{ required: true, message: '用户密码长度为6-20位',trigger:'blur'}
				],
				name:[
					{ required: true, message: '姓名不能为空',trigger:'blur'}
				],
				com:[
					{ required: true, message: '企业名称不能为空',trigger:'blur'}
				],
				pin:[
					{ required: true, message: '身份证号码不能为空',trigger:'blur'},
					{ pattern:/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: '身份证号码格式错误',trigger:'blur'}
				],
				uid:[{ required: true, message: '考勤ID不能为空',trigger:'blur'},
				]
			},
		}
    },
	beforeMount(){
		this.getclass()
	},
    methods: {
		async getclass(){
			await this.$http.get('/api/adminuserclass').then((res)=>{
				if(res.code == 200){
					this.c_class=res.c
					this.b_class=res.b
					this.getdata(1)
				}
			},(err)=>{
			    console.log(err);
			})
		},
		async getdata(e){
			if(e >= 1){
				this.pageNum = e
			}else{
				this.pageNum = 1
			}
		    let url = "/api/adminuser";
		    let params={cid:this.cid,pagenum:this.pageNum}
			await this.$http.post(url, params).then((res)=>{
				console.log(res)
				this.isshow=[]
				this.tableData = res.data
				this.total = res.total
		    },(err)=>{
		        console.log(err);
		    })
		
		},
		async getpin(pin){
			await this.$http.get('/api/kaoqinpin?pin='+pin).then((res)=>{
				if(res.code == 200){
					this.form.name = res.name
					this.form.uid = res.uid
					this.$refs.form.clearValidate();
				}
			},(err)=>{
			    console.log(err);
			})
		},
		upuser(){
			this.$refs.form.validate(valid => {
				console.log(valid)
				// valid: true，false
				// 为false: 表单上会有提示信息
				// 如果是true,就可以进一步发ajax做登录
				if(valid){
					console.log(this.form)
					this.$http.post('/api/adminuseradd',this.form).then((res)=>{
						if(res.code == 200){
							this.open = false
							this.getdata()
						}else{
							this.$message({
								type: 'error',
							    message: res.msg
							  });
						}
					},(err)=>{
					    console.log(err);
					})
				}else{
					this.$message.error("信息格式填写有误,请检查!");
				}
			})
		},
		pininput(e){
			var reg = /^(\d{18}|\d{17}x)$/;
			var str = e;
			if(reg.test(str)){
				this.getpin(str)
			}else{
				this.form.name = ''
				this.form.uid = ''
			}
		},
		dokeshi(b_class,index){
			let [bid,bname]=b_class
			console.log(bid,bname,index)
			this.$confirm('此操作将修改用户科室, 是否继续?', '提示', {
				confirmButtonText: '保存',
				cancelButtonText: '取消更改',
				type: 'warning',
				}).then(() => {
					let url = "/api/adminuserbumen";
					let params={id:this.tableData[index].id,bid:bid}
					console.log('保存');
					this.$http.post(url, params).then((res)=>{
						if(res.code==200){
							this.$message({
								type: 'success',
							    message: res.msg
							  });
							this.tableData[index].bname = bname
							this.tableData[index].bid = bid
						}
					},(err)=>{
					    console.log(err);
					})
			    }).catch(() => {    
						console.log('取消更改');
			    });
		},
		beforeAvatarUpload(file) {
		    const isJPG =
				file.type === "image/jpg" ||
		        file.type === "image/jpeg" ||
		        file.type === "image/png";
		    const isLt1M = file.size / 1024 / 1024 < 1;
			if (!isJPG) {
				this.$message.error("仅支持jpg，png格式的图片！");
			}
			if (!isLt1M) {
				this.$message.error("上传图片大小不能超过 1MB!");
		    }
		    return isJPG && isLt1M;
		},
		async uploadFile(item){
			this.uploadForm = new FormData()
			this.uploadForm.append("image", item.file);
			this.uploadForm.append("id", item.data.id);
			await this.$http.uppic('/api/upusertouxiang',this.uploadForm).then((res)=>{
				if(res.code == 200){
					this.$message({
						type: 'success',
					    message: res.msg
					  });
					this.getdata()
				}
			},(err)=>{
			    console.log(err);
			})
		},
		handelCheck(row,index){
			if(row.name != "" && row.com != ""){
				this.$set(this.isshow, index, false);
				
			}else{
				this.$message({
					type: 'error',
				    message: '数据不能为空'
				  });
			}
			
		},
		handleEdit(index){
			this.$set(this.isshow, index, true);
		},
		handleDelete(index,data){
			console.log(index,data)
			this.$confirm('此操作将彻底删除该用户, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				}).then(() => {
					let url = "/api/adminuserdel";
					let params={id:data.id}
					let res;
					res = this.$http.post(url, params).then((res)=>{
						this.tableData.splice(index, 1);
						this.$message({
							type: 'success',
						    message: res.msg
						  });
					},(err)=>{
					    console.log(err);
					})
			        }).catch(() => {    
						console.log('取消删除');
			        });
		},
		handleshenhe(index,data,type){
			if(type == 1){
				var shenhe = 1;
			}else{
				var shenhe = 0;
			}
			let url = "/api/adminusersh";
			let params={id:data.id,sh:shenhe}
			let res;
			res = this.$http.post(url, params).then((res)=>{
				this.tableData[index].shenhe = shenhe
				this.$message({
					type: 'success',
				    message: res.msg
				  });
			},(err)=>{
			    console.log(err);
			})
		},
		getValue(){
			this.form = {
				cid:0,
				bid:0,
				phone:'',
				pass:'',
				name:'',
				com:'',
				pin:'',
				uid:'',
				}
			this.getdata()
		},
		handleCurrentChange(e){//分页选择
			this.getdata(e)
		},
		prevclick(e){//分页选择上一页
			console.log(e)
		},
		nextclick(e){//分页选择下一页
			console.log(e)
		},
		add(){
			this.form = {
				cid:0,
				bid:'',
				phone:'',
				pass:'',
				name:'',
				com:'',
				pin:'',
				uid:'',
				}
			this.open = true
		},
		addall(){
			
		},
		radioinput(e){
			this.$nextTick(()=>{
				 this.$refs.form.clearValidate();
			});
			
		}
    }
}
</script>
<style>
	.add {
	    border: 1px dashed #409EFF;
	    border-radius: 6px;
		font-size: 50px;
	  }
</style>