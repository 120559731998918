<template>
	<div class="news">
		<template>
			<el-button @click="add()" type="primary" size="small" icon="el-icon-plus" style="margin-right: 20px;">添加</el-button>
			<span style="margin-right: 20px;" slot="suffix">按分类查看:</span>
			<el-radio-group v-model="cid" @change="getValue()">
				<el-radio :label="0">全部</el-radio>
				<!-- 如果选项是通过v-for获得的，那么就要绑定key，不然会有警告 -->
				<el-radio v-for="(item,i) in cname" :key="i" :label="item.cid">{{item.cname}}</el-radio>
			</el-radio-group>
		</template>
    <el-table :data="tableData" style="width: 100%">
		<el-table-column label="ID" width="60">
			<template slot-scope="scope">
				<span>{{ scope.row.id }}</span>
			</template>
		</el-table-column>
		<el-table-column label="行业类型" width="100">
			<template slot-scope="scope">
				<span>{{ scope.row.cname }}</span>
			</template>
		</el-table-column>
		<el-table-column label="标签" width="80">
			<template slot-scope="scope">
				<el-tag effect="dark" size="small">{{ scope.row.bname }}</el-tag>
			</template>
		</el-table-column>
		<el-table-column label="发布人信息" width="180">
			<template slot-scope="scope">
				<el-row>
				  <el-col :span="24"><span>发布人:{{ scope.row.name }}</span></el-col>
				  <el-col :span="24"><span>企业/部门:{{ scope.row.com }}</span></el-col>
				  <el-col :span="24"><span>手机号码:{{ scope.row.phone }}</span></el-col>
				</el-row>
			</template>
		</el-table-column>
		<el-table-column label="主题" width="300">
			<template slot-scope="scope">
				<span class="info">{{ scope.row.info }}</span>
			</template>
		</el-table-column>
		<el-table-column label="图片">
			<template slot-scope="scope">
				<el-image 
				    style="height: 60px;width: 60px;margin-right: 5px;"
					v-for="(image,i) in scope.row.images" :key="i" 
				    :src="'https://xm.wuzhibbs.com/images_up/'+image" 
				    :preview-src-list="addurl(scope.row.images)">
				</el-image>
			</template>
		</el-table-column>
    <el-table-column fixed="right" width="240" label="操作">
        <template slot-scope="scope">
			<el-button v-if="scope.row.shenhe==1"
			size="mini"
			type="primary"
			@click="handleshenhe(scope.$index,scope.row,0)">已审核</el-button>
			<el-button v-if="scope.row.shenhe==0"
			size="mini"
			@click="handleshenhe(scope.$index,scope.row,1)">未审核</el-button>
			<el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
	<el-pagination
	      @current-change="handleCurrentChange"
	      :page-size="10"
	      layout="total, prev, pager, next"
	      :total="total"
		  :current-page="newspageNum"
		  hide-on-single-page
		  background>
	    </el-pagination>
		<el-dialog :visible.sync="open" :center="true" :close-on-click-modal="false" :close-on-press-escape="false" title="新增">
		       <el-form ref="form" :model="form" label-width="80px">
				   <el-form-item label="选择分类">
				     <el-radio-group v-model="form.cid">
				     	<!-- 如果选项是通过v-for获得的，那么就要绑定key，不然会有警告 -->
				     	<el-radio v-for="(item,i) in cname" :key="i" :label="item.cid">{{item.cname}}</el-radio>
				     </el-radio-group>
				   </el-form-item>
				   <el-form-item label="选择标签">
				     <el-radio-group v-model="form.bid">
				     	<!-- 如果选项是通过v-for获得的，那么就要绑定key，不然会有警告 -->
				     	<el-radio v-for="(item,i) in bname" :key="i" :label="item.bid">{{item.bname}}</el-radio>
				     </el-radio-group>
				   </el-form-item>
				   <el-form-item label="手机号码" prop="phone">
					<el-input minlength="11" maxlength="11" v-model="form.phone" placeholder="请输入手机号码"></el-input>
				   </el-form-item>
				   <el-form-item label="需求信息" prop="info">
					   <el-input autosize type="textarea" :rows="2" placeholder="请输入需求内容" v-model="form.info"></el-input>
				   </el-form-item>
				   <el-form-item label="上传图片">
				   		<el-upload
				   			:action="actionUrl"
				   			:auto-upload="false"
				   			list-type="picture-card"
				   			:on-change="uploadFile"
							:on-remove="uploadFile"
							:file-list="fileList"
				   			accept="image/png, image/jpeg"
				   			multiple
				   			:limit="6"
							>
				   		<i class="el-icon-plus"></i>
				   		</el-upload>
				   </el-form-item>
		       </el-form>
			   <el-button @click="uppic()" type="primary" size="small">保存</el-button>
		</el-dialog>

	</div>
</template>

<script>
export default {
	data() {
		return {
			actionUrl:'',
			tableData: [],
			newspageNum:1,
			total:0,
			cid:0,
			cname:[],
			bname:[],
			open:false,
			form: {
				cid:1,
				bid:1,
				phone:'',
				info:''
			},
			fileList:[],
			rules: {
				phone: [
					{ required: true, message: '手机号码不能为空', trigger: 'blur' },
					{ min: 11, max: 11, message: '手机号码格式错误', trigger: 'blur' }
			    ],
			},
			images:[]
		}
    },
	beforeMount(){
		this.$http.get("/api/adminfuwuclass").then((res)=>{
			this.cname = res.c
			this.bname = res.b
		})
		this.getdata(1)
	},
    methods: {
		async uppic(){
			this.uploadForm = new FormData()
			for (let i = 0; i < this.fileList.length; i++) {
				this.uploadForm.append("image[]", this.fileList[i].raw);
			}
			this.uploadForm.append("cid", this.form.cid);
			this.uploadForm.append("bid", this.form.bid);
			this.uploadForm.append("phone", this.form.phone);
			this.uploadForm.append("info", this.form.info);
			await this.$http.uppic('/api/adminpicup',this.uploadForm).then((res)=>{
				if(res.code == 200){
					this.open = false
					this.form = {}
					this.getdata(1)
				}
            },(err)=>{
                console.log(err);
            })
		},
		uploadFile(file, fileList){
			this.fileList = fileList
			console.log(this.fileList)
		},
		getdata(e){
			if(e >= 1){
				this.newspageNum = e
			}else{
				this.newspageNum = 1
			}
		    let url = "/api/adminfuwu";
		    let params={cid:this.cid,pagenum:this.newspageNum}
		    let res;
		    res = this.$http.post(url, params).then((res)=>{
				console.log(res)
				this.tableData = res.data
				this.total = res.total
				
            },(err)=>{
                console.log(err);
            })

		},
		handleDelete(index,data){
			console.log(index,data)
			this.$confirm('此操作将彻底删除该内容, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				}).then(() => {
					let url = "/api/adminfuwudel";
					let params={id:data.id}
					let res;
					res = this.$http.post(url, params).then((res)=>{
						this.tableData.splice(index, 1);
						this.$message({
							type: 'success',
						    message: res.msg
						  });
					},(err)=>{
					    console.log(err);
					})
			        }).catch(() => {    
						console.log('取消删除');
			        });
		},
		handleshenhe(index,data,type){
			if(type == 1){
				var shenhe = 1;
			}else{
				var shenhe = 0;
			}
			let url = "/api/adminfuwush";
			let params={id:data.id,sh:shenhe}
			let res;
			res = this.$http.post(url, params).then((res)=>{
				this.tableData[index].shenhe = shenhe
				this.$message({
					type: 'success',
				    message: res.msg
				  });
			},(err)=>{
			    console.log(err);
			})
		},
		getValue(){
			this.getdata(this.cid)
		},
		handleCurrentChange(e){//分页选择
			this.getdata(e)
		},
		prevclick(e){//分页选择上一页
			console.log(e)
		},
		nextclick(e){//分页选择下一页
			console.log(e)
		},
		add(){
			this.open = true
		},
		addurl(items){
			let newArr = [];
			items.forEach((item) => {
			     item = 'https://xm.wuzhibbs.com/images_up/'+item
			     newArr.push(item);
			})
			return newArr;
		}
    }
}
</script>
<style>
	.news{
		padding-top: 15px;
	}
	.info {
	    display: -webkit-box;
	    text-overflow: ellipsis;
	    overflow: hidden;
	    -webkit-line-clamp: 3;
	    -webkit-box-orient: vertical;
	}
</style>