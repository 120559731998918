<template>
    <div>
<div class="loginBox">
            <div class="loginBg"></div>
            <div  class="signin">
                <div class="loginHtml">
                    <h1 class="loginTitle">系统登录</h1>
                    <el-form :model="Form"  :rules="rules" ref="Form">
                        <el-form-item  prop="username">
                            <el-input v-model="Form.username" placeholder="账号"></el-input>
                        </el-form-item>
                        <el-form-item  prop="password">
                            <el-input show-password v-on:keyup.enter.native="Login('Form')" v-model="Form.password" placeholder="密码"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" style="width: 100%" :loading="loading" @click="Login('Form','pc')">登录</el-button>
                        </el-form-item>
                    </el-form>
                    <div class="clear"></div>
                </div>
                <p class="copyright"> </p>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    export default {
      name: 'Login',
        data() {
          return {
            Form: {
                username:"",
                password:"",
            },
            rules: {},
            loading:false,
          }
        },
        methods: {
          Login() {
            var _this=this;
            this.loading=true;
            axios.post('/api/login', {
                phone: this.Form.username,
                pass: this.Form.password
              })
              .then(function (response) {
                  _this.loading=false;
                  console.log(response);
                  var code=response.data.code;
                  var msg=response.data.msg;
                  if(code!=200){
                      _this.$message({
                          message: msg,
                          type: 'error'
                      });
                      return;
                  }
                  _this.$message({
                      message: msg,
                      type: 'success'
                  });
				  var token = response.data.token;
				  var user = response.data.user;
				  window.localStorage.setItem("token", token);
				  window.localStorage.setItem("user", user);
				  _this.$router.push({ path: "/" });
              })
              .catch(function (error) {
                  _this.loading=false;
                  console.log(error);
                  _this.$message({
                      message: error.message,
                      type: 'error'
                  });
              });
          }
        }

    }
</script>

<style>
    body {
        margin: 0;
        padding: 0;
    }
    .loginBg{
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0px;
        left: 0px;
        background: rgba(0, 0, 0, 0.5);
        opacity: 0.5;
        z-index: 0;
    }
    .loginBox{
        width: 100%;
        height: 100%;
        background: #409EFF;
        background-size: cover;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .clear{clear: both;}
    .signin {
        width: 460px;
        padding: 25px;
        background: #fff;
        box-shadow: 0 25px 30px -13px rgba(40,40,40,.4);
        border-radius: 6px;
        z-index: 1;
    }
    .signin h1,.signin h2,.signin .copyright{
        font-weight: normal;
        color: #4d627b;
        text-align: center;
    }
    .signin .loginTitle{
        font-size: 24px;
        margin: 15px 0px;
    }
    .signin .loginDesc{
        font-size: 14px;
        margin-bottom: 15px;
    }
    .signin .loginDesc .regBtn{
        float: left;
    }
    .signin .loginDesc a{
        text-decoration: none;
        cursor: pointer;
        color: #409EFF;
    }
    .signin .copyright{
        font-size: 12px;
    }
    @media (max-width: 768px) {
        body{
            background-color: #fff;
        }
        .signin{
            width: 80%;
            background-color: #fff;
            box-shadow:none;
        }
    }
</style>