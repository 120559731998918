import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/page/login/index'
import Layout from '@/page/layout'
import Index from '@/page/index'
import News from '@/page/neirong/news'
import Fuwu from '@/page/neirong/fuwu'
import Jiatiao from '@/page/kaoqin/jiatiao'
import User from '@/page/xitong/user'

Vue.use(Router)  // 在vue中注入Router
 // 配置路由路径
const routes =[
	{
		path: '/login',
		name: '登录页面',
		component:Login
	},{
		path: '/',
		redirect:'index',
		component:Layout,
		children:[{
				path: '/index',
				icon: '',
				name: '系统首页',
				component:Index,
				admin:0
			},{
				path: '/news',
				icon: '',
				name: '新闻动态',
				component:News,
				admin:0
			},{
				path: '/fuwu',
				icon: '',
				name: '服务中心',
				component:Fuwu,
				admin:0
			},{
				path: '/yonghu',
				icon: '',
				name: '用户管理',
				component:User,
				admin:0
			},{
				path: '/jiatiao',
				icon: '',
				name: '假条管理',
				component:Jiatiao,
				admin:99
			}
		]
	}
]

// 将路径注入到Router中
var router=new Router({
	routes
})

router.beforeEach((to, from, next) => {
//to到哪儿 from从哪儿离开 next跳转 为空就是放行 
 if (to.path === '/login') {
 //如果跳转为登录，就放行 
 next(); 
 } else {
 //取出localStorage判断
  let token = localStorage.getItem('token');   
  if (token == null || token === '') { 
    console.log('请先登录')  
    next({path: '/login'});
   } else {
     next(); 
   } 
}});
// 导出路由
export default router;