import Element from 'element-ui'
// 打开遮罩层
export function openPageLoading(){
    Element.Loading.service({
        fullscreen: true,
        lock: true,
        text: '数据加载中...',
        spinner: 'el-icon-loading',
        // spinner: 'ri-loader-2-line',
        background: 'rgba(0, 0, 0, 0.6)'
    })
}
// 关闭遮罩层
export function closePageLoading(){
    Element.Loading.service().close();
}