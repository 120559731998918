import axios from 'axios'
import Element from 'element-ui'
import {openPageLoading,closePageLoading} from "@/util/elemenloading"
let apiJsonpParam = null;

const service = axios.create(apiJsonpParam)

let serviceLoadingNum = 0
let serviceLoadingTem = null

service.interceptors.request.use(
    config => {
		if(config.loading==undefined || config.loading==true){
		    clearTimeout(serviceLoadingTem)
		    openPageLoading()
		    serviceLoadingNum ++
		}
        if (config.push === '/login') {
			
		} else {
			if (localStorage.getItem('token')) {
				//在请求头加入token，名字要和后端接收请求头的token名字一样
				config.headers.token=localStorage.getItem('token');
			}
          } 
           return config;
      },
    error=>{
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    response => {
        // 关闭遮罩层
        if(response.config.loading==undefined || response.config.loading==true){
            serviceLoadingNum --
            if(serviceLoadingNum==0){
                serviceLoadingTem = setTimeout(()=>{
                    closePageLoading()
                },10)
            }
        }
		//和后端token失效返回码约定444
		if (response.data.code == 444) {
					// 引用elementui message提示框
					Element.Message({
						message: 'TOKEN验证失败,请重新登陆!',
						type: 'err',
					});
					//清除token
					localStorage.removeItem('token');
					//跳转
					router.push({ path: "/login" });
				}
		// 系统内接口业务报错统一处理
        if (response.data.code != 200) {
            let message = response.data.message || response.data.msg || response.msg;
            if(response.data.code){
                handleError(response.data.code, message);
                return Promise.reject(response.data)
            }          
        } else {
            return Promise.resolve(response.data);
           
        }
    },
    error => {
        if(error && error.response){
            if(error.response.config.loading==undefined || error.response.config.loading==true){
                serviceLoadingNum --
                if(serviceLoadingNum==0){
                    serviceLoadingTem = setTimeout(()=>{
                        closePageLoading()
                    },10)
                }
            }
            handleError(error.response.status)
            return Promise.reject(error.response)
        }
        return Promise.reject(error)
    }
)
export function handleError(errorCode,errormsg=''){
    switch (errorCode) {
        case 500:
                let errMsg=errormsg||"服务正在维护中，请稍后...";
                // Element.Message(errMsg);
                Element.Message({
                    showClose: true,
                    message: errMsg,
                    type: 'error'
                  });
                break;
        // 其他错误，直接抛出错误提示
        default:
                Element.Message({
                    showClose: true,
                    message:errormsg?errormsg:'服务发生异常，请稍后重试！',
                    type: 'error'
                })
    }
}

export default service